<template>
  <div class="candle-list space-y-4">
    <div class="card bg-white shadow-sm rounded-lg px-3 py-2"
         v-for="candle in candles"
         :key="candle.id">
      <div class="flex items-center justify-between mb-2">
        <div class="flex items-center gap-2">
          <icon-star v-if="candle.is_favorite" :checked="candle.is_favorite" />
          <span class="font-semibold text-lg">{{ candle.candle_reference }}</span>
        </div>
        <candle-status-badge :status="candle.candle_status" />
      </div>
      <div class="flex flex-col sm:flex-row justify-between gap-2">
        <div class="flex flex-col gap-2">
          <div class="flex gap-2 items-center">
            <h3 class="font-bold w-20">Date:</h3>
            {{ candle.candle_date | isodate }}
          </div>
          <div class="flex gap-2 items-center">
            <h3 class="font-bold w-20">Brand:</h3>
            <div>{{ candle.brand_text }}</div>
          </div>
          <div class="flex gap-2 items-center">
            <h3 class="font-bold w-20">Glass:</h3>
            <div>{{ candle.glass_text }}</div>
          </div>
          <div class="flex gap-2 items-center">
            <h3 class="font-bold w-20">Wax:</h3>
            <div>{{ candle.wax_text }}</div>
          </div>
          <div class="flex gap-2 items-center">
            <h3 class="font-bold w-20">Wicks:</h3>
            <div>
              <span v-if="candle.wick_count > 1">{{ candle.wick_count }}x </span>
              <span>{{ candle.wick_text }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex gap-2">
            <h3 class="w-20 font-bold">Additives:</h3>
            <span v-if="candle.additive.length">
              <div
                v-for="additive in candle.additive"
                :key="additive.id"
                class="flex gap-4 justify-between"
              >
                <div>{{ additive.name_text }}</div>
                <div>{{ additive.pct_text }}</div>
              </div>
            </span>
            <span v-else>None</span>
          </div>
          <div class="flex gap-2">
            <h3 class="w-20 font-bold">Dyes:</h3>
            <span v-if="candle.dye.length">
              <div v-for="dye in candle.dye" :key="dye.id">
                {{ dye.name_text }}
              </div>
            </span>
            <span v-else>None</span>
          </div>
          <div class="flex gap-2">
            <h3 class="w-20 font-bold">Fragrances:</h3>
            <span v-if="candle.fragrance.length">
              <div
                v-for="fragrance in candle.fragrance"
                :key="fragrance.id"
                class="flex gap-4 justify-between"
              >
                <div>{{ fragrance.name_text }}</div>
                <div>{{ fragrance.pct_text }}</div>
              </div>
            </span>
            <span v-else>None</span>
          </div>
        </div>
      </div>
      <div class="mt-4 text-right">
        <router-link :to="getCandleLink(candle)" class="btn btn-primary btn-sm">
          View
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getCandleLink } from '@/composables/candle';

const IconStar = () => import('@/components/global/IconStar.vue');
const CandleStatusBadge = () => import('@/components/candle/CandleStatusBadge.vue');

export default {
  name: 'MobileTable',
  components: {
    IconStar,
    CandleStatusBadge,
  },
  props: {
    candles: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getCandleLink(candle) {
      return getCandleLink(candle, this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
